<template>
  <Notifications />
  <router-view />
</template>


<script lang="ts">
import { Options, Vue } from "vue-class-component"
import { storageHttpService } from "./core/services"

@Options({})
export default class App extends Vue {
  public api: any

  // Hooks

  async mounted(): Promise<void> {
    // this.performanceCalculationDemo();
  }

  errorCaptured(err: Error, vm: Vue, info: string): boolean | undefined {
    console.error(err)
    this.$notifyError("An unknown error occurred")
    return true
  }
}
</script>
