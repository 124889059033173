import "@vuepic/vue-datepicker/dist/main.css"
import "./styles/global.scss"

import Notifications from "@/plugins/notifications.plugin"
import VueDatePicker from "@vuepic/vue-datepicker"
import { createPinia } from "pinia"
import VTooltip from "v-tooltip"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"


createApp(App)
    .use(createPinia())
    .use(router)
    .use(Notifications)
    .use(VTooltip)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')
